import * as apiService from 'lib/apollo/client'
import { transformNote, transformNotes } from 'stores/smartfields/transforms'
import { EntityId } from 'types/entity.interface'
import * as GQL from 'types/graphql'
import DataError, { IsError } from '../../helpers/dataError'

class NoteService {
  async getNotesByAppointmentId(appointmentId: EntityId) {
    const { appointmentNotes: resp } = await apiService.query<
      GQL.AppointmentNotesQuery,
      GQL.AppointmentNotesQueryVariables
    >(GQL.AppointmentNotesDocument, { apptId: appointmentId }, { fetchPolicy: 'no-cache' })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNotes(resp.notes)
  }

  async getAllNotesByClientId(clientId: EntityId) {
    const { allNotes: resp } = await apiService.query<GQL.AllNotesQuery, GQL.AllNotesQueryVariables>(
      GQL.AllNotesDocument,
      {
        clientId,
      },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNotes(resp.notes)
  }

  async geNotesByClientId(filters: { clientId: EntityId; limit: number; offset: number; filters?: string }) {
    const { getNotes: resp } = await apiService.query<GQL.GetNotesQuery, GQL.GetNotesQueryVariables>(
      GQL.GetNotesDocument,
      {
        filters,
      },
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNotes(resp.notes)
  }

  async getNote(noteId: EntityId) {
    const { note: resp } = await apiService.query<GQL.NoteQuery, GQL.NoteQueryVariables>(GQL.NoteDocument, { noteId })
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNote(resp)
  }

  async getNotesCount() {
    const { getNotesCount: resp } = await apiService.query<GQL.GetNotesCountQuery, GQL.GetNotesCountQueryVariables>(
      GQL.GetNotesCountDocument,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.count
  }

  async getAddedNotes(payload: GQL.GetAddedNotesQueryVariables) {
    const { getAddedNotes: resp } = await apiService.query<GQL.GetAddedNotesQuery, GQL.GetAddedNotesQueryVariables>(
      GQL.GetAddedNotesDocument,
      payload,
      { fetchPolicy: 'no-cache' },
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async reorderAppointmentNotes(payload: GQL.ReorderNotesMutationVariables) {
    const { reorderNotes: resp } = await apiService.mutate<GQL.ReorderNotesMutation, GQL.ReorderNotesMutationVariables>(
      GQL.ReorderNotesDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async createNote(payload: GQL.AddNoteMutationVariables) {
    const { addNote: resp } = await apiService.mutate<GQL.AddNoteMutation, GQL.AddNoteMutationVariables>(
      GQL.AddNoteDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNote(resp)
  }

  async updateNote(payload: GQL.UpdateNoteMutationVariables) {
    const { updateNote: resp } = await apiService.mutate<GQL.UpdateNoteMutation, GQL.UpdateNoteMutationVariables>(
      GQL.UpdateNoteDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async bulkAddNotes(payload: GQL.BulkAddNoteMutationVariables) {
    const { bulkAddNote: resp } = await apiService.mutate<GQL.BulkAddNoteMutation, GQL.BulkAddNoteMutationVariables>(
      GQL.BulkAddNoteDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return transformNotes(resp.notes)
  }

  async updateNotesInBulk(payload: GQL.UpdateNotesInBulkMutationVariables) {
    const { updateNotesInBulk: resp } = await apiService.mutate<
      GQL.UpdateNotesInBulkMutation,
      GQL.UpdateNotesInBulkMutationVariables
    >(GQL.UpdateNotesInBulkDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.accepted
  }

  async deleteNote(payload: GQL.DeleteNoteMutationVariables) {
    const { deleteNote: resp } = await apiService.mutate<GQL.DeleteNoteMutation, GQL.DeleteNoteMutationVariables>(
      GQL.DeleteNoteDocument,
      payload,
    )
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async changeAssignee(payload: GQL.ChangeAssigneeMutationVariables) {
    const { changeAssignee: resp } = await apiService.mutate<
      GQL.ChangeAssigneeMutation,
      GQL.ChangeAssigneeMutationVariables
    >(GQL.ChangeAssigneeDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.id
  }

  async transferNotes(payload: GQL.TransferNotesMutationVariables) {
    const { transferNotes: resp } = await apiService.mutate<
      GQL.TransferNotesMutation,
      GQL.TransferNotesMutationVariables
    >(GQL.TransferNotesDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.accepted
  }

  async setAddedNotes(payload: GQL.SetAddedNotesMutationVariables) {
    const { setAddedNotes: resp } = await apiService.mutate<
      GQL.SetAddedNotesMutation,
      GQL.SetAddedNotesMutationVariables
    >(GQL.SetAddedNotesDocument, payload)
    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }
}

export default new NoteService()
