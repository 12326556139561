/* eslint-disable react-hooks/exhaustive-deps */
import { FormInstance } from 'antd'
import { HolistiplanConfig } from 'components/drawers/Smartfields/types/integration'
import { PROVIDERS, isConnected } from 'constants/connections'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { allClients, loadTaxRecords } from 'stores/services/holistiplan.service'
import { smartfieldStore } from 'stores/smartfields'
import Str from '../../../../../../stores/util/str.util'
import { ERRORS } from './holistiplan.errors'
import { HolistiplanClient, HolistiplanTaxRecord } from './holistiplan.types'

export const useHolistiplanConnection = () => {
  const [connected, setConnected] = useState(true)

  useEffect(() => {
    const connection = global.connections.getConnection(PROVIDERS.HOLISTIPLAN)
    setConnected((connection && isConnected(connection.state)) ?? false)
  }, [])

  return useMemo(
    () => ({
      connected,
    }),
    [connected],
  )
}

export const useHolistiplanData = (config: HolistiplanConfig, form?: FormInstance, uid: string = 'uid') => {
  const [clients, setClients] = useState<HolistiplanClient[]>([])
  const [taxRecords, setTaxRecords] = useState<HolistiplanTaxRecord[]>([])
  const [error, setError] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(true)

  const { connected } = useHolistiplanConnection()

  const onSelectClient = useCallback(
    (clientId: string) => {
      form?.setFieldValue('year', null)
      setLoading(true)

      loadTaxRecords({ clientId })
        .then((trs: HolistiplanTaxRecord[]) => {
          if (!trs || !trs.length) {
            setError(ERRORS.NO_TAX_RECORDS_ERROR)
            setLoading(false)
            return
          }

          setTaxRecords(trs.sort((a, b) => b.input_year - a.input_year))
          setError(undefined)
          setLoading(false)
        })
        .catch(() => {
          setError(ERRORS.LOAD_TAX_RECORDS_ERROR)
          setTaxRecords([])
          setLoading(false)
        })
    },
    [clients, form],
  )

  const onSelectInputYear = useCallback(
    (year: number) => {
      const record = taxRecords.find((tr) => tr.id === year)
      const value = (record as any)?.[config.field]

      if (!value) {
        setError(`${ERRORS.FIELD_ERROR} ${Str.startCase(config.field)}`)
        return form?.setFieldValue('year', null)
      }

      // updates the remirror preview and stores values
      smartfieldStore.update({ [uid]: value })
      // sets the value to hidden antd form.item, this is used
      // to do the actual replacement when closing the modal
      form?.setFieldValue(uid, value)
    },
    [config.field, form, taxRecords, uid],
  )

  const setHolistiplanError = (err: string) => {
    global.holistiplan._clientId = null
    setError(err)
    setClients([])
    setTaxRecords([])
  }

  useEffect(() => {
    if (!connected) {
      return setHolistiplanError(ERRORS.INTEGRATION_ERROR)
    }

    setTaxRecords([])
    setClients([])
    setError(undefined)

    allClients()
      .then((cls) => {
        if (!cls || !cls.length) {
          return setHolistiplanError(ERRORS.NO_CONTACTS_ERROR)
        }

        setClients(cls)
      })
      .catch(() => {
        return setHolistiplanError(ERRORS.LOAD_CONTACTS_ERROR)
      })
  }, [uid, connected])

  return useMemo(
    () => ({
      clients,
      taxRecords,
      loading,
      error,
      onSelectInputYear,
      onSelectClient,
    }),
    [clients, taxRecords, loading, error, onSelectInputYear],
  )
}
