import { useEffect, useState } from 'react'

interface Provider {
  value: string
  label: string
  icon: any
  disabled: boolean
}

export const useProviders = (supportedProviders: string[] = []) => {
  const [providers, setProviders] = useState<Provider[]>([])

  useEffect(() => {
    const connections = global.connections.installed
    setProviders(
      connections.map((conn) => ({
        value: conn.identifier,
        label: !supportedProviders.includes(conn.local) ? `${conn.name} (coming soon)` : conn.name,
        icon: conn.Icon,
        disabled: !supportedProviders.includes(conn.local),
      })),
    )
  }, [])

  return {
    providers,
  }
}
