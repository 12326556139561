export const taxRecordFields = {
  input_year: 'Input Year',
  household_name: 'Household Name',
  household_state: 'Household State',
  household: 'Household',
  tax_payer_1: 'Tax Payer 1',
  tax_payer_2: 'Tax Payer 2',
  filing_status: 'Filing Status',
  total_income: 'Total Income',
  agi: 'AGI',
  total_deductions: 'Total Deductions',
  total_tax: 'Total Tax',
  total_deductible_taxes: 'Total Deductable Taxes',
  taxable_income: 'Taxable Income',
  ordinary_income: 'Ordinary Income',
  marginal_bracket: 'Marginal Bracket',
  total_capital_gains: 'Total Capital Gains',
  average_rate: 'Average Rate',
  marginal_cap_gains_bracket: 'Marginal Cap. Gains Bracket',
  penalty_proof: 'Penalty Proof',
  tax_exempt_interest: 'Tax Exempt Interest',
  qualified_dividends: 'Qualified Dividends',
  ordinary_dividends: 'Ordinary Dividends',
  short_term_capital_gains: 'Short Term Cap. Gains',
  long_term_capital_gains: 'Long Term Cap. Gains',
  short_term_capital_loss_carryover: 'Short Term Cap. Loss Carryover',
  long_term_capital_loss_carryover: 'Long Term Cap. Loss Carryover',
  credits: 'Credits',
  medicare_magi: 'Medicare MAGI',
  medicare_premium_increase: 'Medicare Premium Increase',
  itemized_deduction: 'Itemized Deduction',
  medical_expenses: 'Medical Expenses',
  total_itemized_interest: 'Total Itemized Interest',
  charitable_deduction: 'Charitable Deduction',
  amt_2c: 'AMT - Line 2C',
  amt_8: 'AMT 8',
  tax_letter_note: 'Tax Letter Note',
}
